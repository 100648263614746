import {
    Button,
    Checkbox, Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid, IconButton,
    InputLabel, Radio,
    Stack, TableCell,
    TextField, Tooltip, Typography
} from "@mui/material";
import MainCard from "../../components/MainCard";
import {Form, Formik} from "formik";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {CloseCircle, Edit2, Eye, Send, Trash} from "iconsax-react";
import {useCallback, useEffect, useMemo, useState} from "react";
import draftToHtml from "draftjs-to-html";
import axios from "../../utils/axios";
import {enqueueSnackbar} from "notistack";
import ScrollX from "../../components/ScrollX";
import {ReactExpandableTable} from "../../components/ReactTable";
import moment from "moment/moment";
import htmlToDraft from "html-to-draftjs";

const SiteBanners = () => {
    const [sites, setSites] = useState([])
    const [isMainBanner, setBannerMode] = useState(true)
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        loadData()
    }, [])

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setCheckedItems([
                ...checkedItems,
                parseInt(event.target.id)
            ])
        } else {
            setCheckedItems(checkedItems.filter(item => item !== parseInt(event.target.id)))
        }
    };


    const loadData = async () => {
        try {
            let servers = await axios.get("/admin/sites/with-banners")
            setSites(servers.data)
        } catch (e) {

        }
    }

    const add = async (values, helper) => {
        try {
            const contentState = editorState.getCurrentContent();
            const rawContentState = convertToRaw(contentState);
            const html = draftToHtml(rawContentState);

            await axios.post("/admin/sites/banners", {
                type: isMainBanner ? "main" : "second",
                content: html,
                site_ids: checkedItems
            })
            enqueueSnackbar('بنر با موفقیت اضافه شد', {variant: 'success'})
            setEditorState(EditorState.createWithText(""))
            setCheckedItems([])
            helper.resetForm()
            loadData()
        } catch (e) {
            enqueueSnackbar('ثبت بنر با مشکل مواجه شد', {variant: 'error'})
        }
    }


    const columns = useMemo(
        () => [
            {
                Header: () => null,
                accessor: 'id',
                className: 'cell-center',
                disableFilters: true
            },
            {
                Header: 'نام سایت',
                accessor: 'name',
                disableFilters: true
            },
            {
                Header: 'آدرس',
                accessor: 'url',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({row}) => {
                    return (
                        <Typography><a href={'https://' + row.values.url}
                                       target={"_blank"}>{row.values.url}</a></Typography>
                    )
                }
            },
            {
                Header: 'وضعیت بنر اصلی',
                accessor: 'main_banner',
                className: 'cell-center',
                disableSortBy: true,
                disableFilters: true
            },
            {
                Header: 'وضعیت بنر سایر',
                accessor: 'second_banner',
                className: 'cell-center',
                disableSortBy: true,
                disableFilters: true
            },
            {
                Header: 'زمان انتشار',
                className: 'cell-center',
                accessor: 'created_at',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({row}) => {
                    const formattedDate = moment(row.values.created_at).format("YYYY-MM-DD HH:mm");
                    return (
                        <Typography dir={"ltr"}>{formattedDate}</Typography>
                    )
                }
            },
            {
                Header: 'عملیات',
                className: 'cell-center',
                disableSortBy: true,
                Cell: ({row}) => {
                    return (
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                            <Tooltip title={row.isExpanded ? "بستن" : "مشاهده"}>
                                <IconButton
                                    color="primary"
                                    onClick={(e) => {
                                        row.toggleRowExpanded();
                                    }}
                                >
                                    {row.isExpanded ? <CloseCircle/> : <Eye/>}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"ویرایش بنر اصلی"}>
                                <IconButton
                                    color="primary"
                                    onClick={(e) => {
                                        {
                                            handleHtmlInput(row.original.main_banner_content)
                                            setCheckedItems([row.values.id])
                                        }
                                    }}
                                >
                                    <Edit2/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"ویرایش بنر سایر صفحات"}>
                                <IconButton
                                    color="primary"
                                    onClick={(e) => {
                                        handleHtmlInput(row.original.second_banner_content)
                                        setCheckedItems([row.values.id])
                                    }}
                                >
                                    <Edit2/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    );
                }
            }
        ],
        []
    );

    const handleHtmlInput = (htmlString) => {
        const blocksFromHtml = htmlToDraft(htmlString);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
    };

    const renderRowSubComponent = useCallback(({row}) =>
            <TableCell colSpan={8}>
                <div>
                    <div className={'cell-center'}>بنر اصلی</div>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: row.original.main_banner_content}}/>
                </div>
                <Divider/>
                <br/>
                <div>
                    <div className={'cell-center'}>بنر سایر صفحات</div>
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: row.original.second_banner_content}}/>
                </div>
            </TableCell>
        , [sites]);


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MainCard title={"وضعیت اطلاعیه ها"}>
                        {sites && <MainCard content={false}>
                            <ScrollX>
                                <ReactExpandableTable columns={columns} data={sites}
                                                      renderRowSubComponent={renderRowSubComponent}/>
                            </ScrollX>
                        </MainCard>}
                    </MainCard>
                </Grid>
                <Grid item xs={12}>
                    <MainCard title="افزودن اعلان جدید">
                        <Formik
                            enableReinitialize={false}
                            initialValues={{
                                id: 0,
                                title: '',
                            }}
                            onSubmit={add}>
                            {({
                                  handleBlur,
                                  errors,
                                  handleChange,
                                  handleSubmit,
                                  values,
                                  isValid,
                                  setFieldValue,
                                  touched
                              }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <InputLabel>نوع اطلاعیه را مشخص کنید</InputLabel>
                                                <FormControl component="fieldset">
                                                    <FormGroup aria-label="position" row>
                                                        <FormControlLabel
                                                            control={<Radio checked={isMainBanner}
                                                                            onChange={() => setBannerMode(true)}/>}
                                                            label={"بنر صفحه اصلی"} labelPlacement="end"
                                                            sx={{ml: 1}}/>

                                                        <FormControlLabel
                                                            control={<Radio checked={!isMainBanner}
                                                                            onChange={() => setBannerMode(false)}/>}
                                                            label={"بنر سایر صفحات"} labelPlacement="end"
                                                            sx={{ml: 1}}/>
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} minHeight={300} width={200}>
                                                <InputLabel>متن اعلان را بنویسید</InputLabel>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(editor) => setEditorState(editor)}
                                                />
                                                <FormHelperText error={touched.content && errors.content}>محتوای اعلان
                                                    باید حداقل دارای 50 حرف باشد</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel>اعلان در این سایت ها برای تمامی کاربران نمایش داده
                                                    شود:</InputLabel>
                                                <FormControl component="fieldset">
                                                    <FormGroup aria-label="position" row
                                                               onChange={(e) => console.log(e)}>
                                                        {sites.map((e) => {
                                                            return (
                                                                <FormControlLabel
                                                                    control={<Checkbox id={e.id}
                                                                                       checked={checkedItems.includes(e.id)}
                                                                                       onChange={handleCheckboxChange}/>}
                                                                    label={e.name} labelPlacement="end"
                                                                    sx={{ml: 1}}/>
                                                            )
                                                        })}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} textAlign={'end'}>
                                                <Button type={'submit'} color="primary" variant="contained"
                                                        startIcon={<Send/>}>
                                                    ذخیره و ثبت
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </MainCard>
                </Grid>
            </Grid>
        </div>
    )
}
export default SiteBanners