import {
    Box,
    Divider,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {useParams} from "react-router";
import axios from "../../utils/axios";
import React, {useEffect, useMemo, useState} from "react";
import MainCard from "../../components/MainCard";
import {useTheme} from "@mui/material/styles";
import EcommerceDataChart from "../../sections/widget/chart/EcommerceDataChart";
import {Edit, Eye, Trash, Warning2} from "iconsax-react";
import ReactApexChart from "react-apexcharts";
import {ThemeMode} from "../../config";
import Loader from "../../components/Loader";
import ScrollX from "../../components/ScrollX";
import ReactTable from "../../components/ReactTable";


const DataChart = ({color, series}) => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    // chart options
    const areaChartOptions = {
        chart: {
            id: 'new-users-chart',
            type: 'area',
            sparkline: {enabled: true},
            offsetX: -1
        },
        stroke: {
            width: 1
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                type: 'vertical',
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        series: [
            {
                data: [1, 1, 60, 1, 1, 50, 1, 1, 40, 1, 1, 25, 0]
            }
        ],
        tooltip: {
            fixed: {enabled: false},
            x: {show: false},
            y: {
                title: {
                    formatter: () => ''
                }
            }
        }
    };
    const {primary, secondary} = theme.palette.text;
    const line = theme.palette.divider;

    const [options, setOptions] = useState(areaChartOptions);

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            colors: [color],
            theme: {
                mode: mode === ThemeMode.DARK ? 'dark' : 'light'
            }
        }));
    }, [mode, primary, secondary, line, theme]);


    return <ReactApexChart options={options} series={series} type="area" height={80}/>;
};

const AppDetail = () => {
    const {appId} = useParams();
    const [appData, setAppData] = useState({});
    const [usersList, setUsersList] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPage: 1
    })
    const [newUsersMode, setNewUsersMode] = useState({
        windows: 'week',
        android: 'week',
        ios: 'week'
    })
    const [activeUsersMode, setActiveUsersMode] = useState({
        windows: 'week',
        android: 'week',
        ios: 'week'
    })

    const theme = useTheme();

    useEffect(() => {
        loadAppUserBalance()
        loadUsersList(1)
    }, [])

    const loadAppUserBalance = async () => {
        try {
            let response = await axios.get(`/admin/apps/${appId}`)
            setAppData(response.data)
        } catch (e) {

        }
    }

    const loadUsersList = async (page) => {
        try {
            let response = await axios.get(`/admin/apps/${appId}/users?page=${page}`)
            setUsersList(response.data.data)
            setPagination({
                currentPage: response.data.current_page,
                totalPage: Math.ceil(response.data.total / response.data.per_page)
            })
        } catch (e) {

        }
    }


    const columns = useMemo(
        () => [
            {
                Header: 'ایدی',
                accessor: 'id',
                className: 'cell-center',
                disableFilters: true
            }, {
                Header: 'شناسه',
                accessor: 'uuid',
                className: 'cell-center',
                disableFilters: true
            },
            {
                Header: 'ورژن اپ',
                accessor: 'app_version',
                disableFilters: true
            },
            {
                Header: 'پلتفرم',
                accessor: 'platform',
                disableSortBy: true,
                disableFilters: true
            },
            {
                Header: 'اطلاعات دستگاه',
                accessor: 'device_info',
                disableSortBy: true,
                disableFilters: true
            },
        ],
        []
    );

    const {info, users, active_users, new_users} = appData


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {typeof info == "undefined" && <Loader/>}
                {info && users && <MainCard title="اطلاعات اپلیکیشن" >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MainCard>
                                <Stack direction="row" alignItems="center" justifyContent="space-between"
                                       spacing={1}>
                                    <Typography variant="h7">نام اپلیکیشن</Typography>
                                    <Typography variant="h5">{info.name}</Typography>
                                </Stack>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MainCard>
                                <Stack direction="row" alignItems="center" justifyContent="space-between"
                                       spacing={1}>
                                    <Typography variant="h7">شناسه فرانت</Typography>
                                    <Typography variant="h5">{info.front_id}</Typography>
                                </Stack>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MainCard>
                                <Stack direction="row" alignItems="center" justifyContent="space-between"
                                       spacing={1}>
                                    <Typography variant="h7">آخرین نسخه</Typography>
                                    <Typography variant="h5">{info.version}</Typography>
                                </Stack>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MainCard>
                                <Stack direction="row" alignItems="center" justifyContent="space-between"
                                       spacing={1}>
                                    <Typography variant="h7">کل کاربران اندروید</Typography>
                                    <Typography variant="h5">{users?.android}</Typography>
                                </Stack>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MainCard>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                    <Typography variant="h7">کل کاربران ویندوز</Typography>
                                    <Typography variant="h5">{users?.windows}</Typography>
                                </Stack>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <MainCard>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                    <Typography variant="h7">کل کاربران iOS</Typography>
                                    <Typography variant="h5">{users?.iOS}</Typography>
                                </Stack>
                            </MainCard>
                        </Grid>
                    </Grid>
                    <Box marginTop={2}>
                        <Stack direction="row" alignItems="start" spacing={1}>
                            <Warning2/>
                            <Typography variant="h7">کاربرانی که اپلیکیشن را حذف کرده اند در این آمار مشخص
                                نیستند</Typography>
                        </Stack>
                    </Box>

                </MainCard>}
            </Grid>
            {new_users && <Grid item container xs={12} spacing={3}>

                <Grid item xs={12} sm={6} lg={4}>
                    <MainCard >
                        <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
                            <Typography variant="h5">کاربران جدید اندروید</Typography>
                            <Box sx={{minWidth: 120}}>
                                <FormControl fullWidth size="small">
                                    <Select id="demo-simple-select" value={newUsersMode.android} onChange={(e) => {
                                        setNewUsersMode({
                                            ...newUsersMode,
                                            android: e.target.value
                                        })
                                    }}>
                                        <MenuItem value={'week'}>هفته اخیر</MenuItem>
                                        <MenuItem value={'month'}>ماه اخیر</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box paddingTop={4}>
                            <EcommerceDataChart color={theme.palette.warning.dark} series={[
                                {
                                    name: 'Users',
                                    data: new_users['android'][newUsersMode.android]
                                }
                            ]}/>
                        </Box>
                    </MainCard>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <MainCard >
                        <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
                            <Typography variant="h5">کاربران جدید ویندوز</Typography>
                            <Box sx={{minWidth: 120}}>
                                <FormControl fullWidth size="small">
                                    <Select id="demo-simple-select" value={newUsersMode.windows} onChange={(e) => {
                                        setNewUsersMode({
                                            ...newUsersMode,
                                            windows: e.target.value
                                        })
                                    }}>
                                        <MenuItem value={'week'}>هفته اخیر</MenuItem>
                                        <MenuItem value={'month'}>ماه اخیر</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box paddingTop={4}>
                            <EcommerceDataChart color={theme.palette.error.dark} series={[
                                {
                                    name: 'Users',
                                    data: new_users['windows'][newUsersMode.windows]
                                }
                            ]}/>
                        </Box>
                    </MainCard>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <MainCard >
                        <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
                            <Typography variant="h5">کاربران جدید iOS</Typography>
                            <Box sx={{minWidth: 120}}>
                                <FormControl fullWidth size="small">
                                    <Select id="demo-simple-select" value={newUsersMode.ios} onChange={(e) => {
                                        setNewUsersMode({
                                            ...newUsersMode,
                                            ios: e.target.value
                                        })
                                    }}>
                                        <MenuItem value={'week'}>هفته اخیر</MenuItem>
                                        <MenuItem value={'month'}>ماه اخیر</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box paddingTop={4}>
                            <EcommerceDataChart color={theme.palette.info.dark} series={[
                                {
                                    name: 'Users',
                                    data: new_users['ios'][newUsersMode.ios]
                                }
                            ]}/>
                        </Box>
                    </MainCard>
                </Grid>
            </Grid>}
            {active_users && <Grid item container spacing={3}>
                <Grid item xs={12} sm={6} lg={4}>
                    <MainCard >
                        <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
                            <Typography variant="h5">تعداد کاربران فعال اندروید</Typography>
                            <Box sx={{minWidth: 120}}>
                                <FormControl fullWidth size="small">
                                    <Select id="demo-simple-select" value={activeUsersMode.android} onChange={(e) => {
                                        setActiveUsersMode({
                                            ...activeUsersMode,
                                            android: e.target.value
                                        })
                                    }}>
                                        <MenuItem value={'week'}>هفته اخیر</MenuItem>
                                        <MenuItem value={'month'}>ماه اخیر</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box paddingTop={4}>
                            <DataChart color={theme.palette.warning.dark} series={[
                                {
                                    name: 'Users',
                                    data: active_users['android'][activeUsersMode.android]
                                }
                            ]}/>
                        </Box>
                    </MainCard>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                    <MainCard >
                        <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
                            <Typography variant="h5">تعداد کاربران فعال ویندوز</Typography>
                            <Box sx={{minWidth: 120}}>
                                <FormControl fullWidth size="small">
                                    <Select id="demo-simple-select" value={activeUsersMode.windows} onChange={(e) => {
                                        setActiveUsersMode({
                                            ...activeUsersMode,
                                            windows: e.target.value
                                        })
                                    }}>
                                        <MenuItem value={'week'}>هفته اخیر</MenuItem>
                                        <MenuItem value={'month'}>ماه اخیر</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box paddingTop={4}>
                            <DataChart color={theme.palette.error.dark} series={[
                                {
                                    name: 'Users',
                                    data: active_users['windows'][activeUsersMode.windows]
                                }
                            ]}/>
                        </Box>
                    </MainCard>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                    <MainCard >
                        <Stack direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
                            <Typography variant="h5">تعداد کاربران فعال iOS</Typography>
                            <Box sx={{minWidth: 120}}>
                                <FormControl fullWidth size="small">
                                    <Select id="demo-simple-select" value={activeUsersMode.ios} onChange={(e) => {
                                        setActiveUsersMode({
                                            ...activeUsersMode,
                                            ios: e.target.value
                                        })
                                    }}>
                                        <MenuItem value={'week'}>هفته اخیر</MenuItem>
                                        <MenuItem value={'month'}>ماه اخیر</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box paddingTop={4}>
                            <DataChart color={theme.palette.info.dark} series={[
                                {
                                    name: 'Users',
                                    data: active_users['ios'][activeUsersMode.ios]
                                }
                            ]}/>
                        </Box>
                    </MainCard>
                </Grid>
            </Grid>}

            <Grid item xs={12}>
                <MainCard content={false} >
                    <ScrollX>
                        <ReactTable columns={columns} data={usersList}/>
                    </ScrollX>
                </MainCard>
                <Box paddingTop={3}>
                    <Pagination count={pagination.totalPage} defaultPage={pagination.currentPage}
                                onChange={(e, page) => loadUsersList(page)} color={'primary'} variant={'contained'}
                                shape={'circular'}/>
                </Box>
            </Grid>

        </Grid>
    )
}
export default AppDetail