

// ==============================|| REACT TABLE ||============================== //

import {useExpanded, useTable} from "react-table";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import PropTypes from "prop-types";
import {Fragment} from "react";

function ReactTable({ columns, data, striped }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data
    });

    return (
        <Table {...getTableProps()}>
            <TableHead>
                {headerGroups.map((headerGroup) => (
                    <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                {column.render('Header')}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <TableRow key={row} {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                    {cell.render('Cell')}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}
ReactTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    striped: PropTypes.bool
};
export default ReactTable



export function ReactExpandableTable({ columns: userColumns, data, renderRowSubComponent }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
        {
            columns: userColumns,
            data
        },
        useExpanded
    );

    return (
        <Table {...getTableProps()}>
            <TableHead>
                {headerGroups.map((headerGroup) => (
                    <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                {column.render('Header')}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();

                    return (
                        <Fragment key={i}>
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                        {cell.render('Cell')}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })}
                        </Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
}

ReactExpandableTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    renderRowSubComponent: PropTypes.any
};
