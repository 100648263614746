import {Button, Dialog, DialogContent, Stack, Typography} from "@mui/material";
import {PopupTransition} from "./@extended/Transitions";
import PropTypes from "prop-types";

export default function AlertDelete({title,data, open, handleClose}) {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            TransitionComponent={PopupTransition}
            maxWidth="xs"
            aria-labelledby="column-delete-title"
            aria-describedby="column-delete-description"
        >
            <DialogContent sx={{mt: 2, my: 1}}>
                <Stack alignItems="center" spacing={3.5}>
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            {title}
                        </Typography>
                        <Typography variant="body1" align="center">
                            مطمئنی میخوای این آیتم حذف بشه؟
                            <br/>
                            بعد از حذف امکان بازگشت آیتم وجود نداره.
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{width: 1}}>
                        <Button fullWidth onClick={() => handleClose(data, false)} color="secondary" variant="outlined">
                            لغو
                        </Button>
                        <Button fullWidth color="error" variant="contained" onClick={() => handleClose(data, true)}
                                autoFocus>
                            حذف
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

AlertDelete.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

