import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField, Typography
} from "@mui/material";
import MainCard from "../../components/MainCard";
import {Field, Form, Formik} from "formik";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {Send} from "iconsax-react";
import React, {createRef, useEffect, useMemo, useState} from "react";
import * as yup from "yup";
import draftToHtml from "draftjs-to-html";
import axios from "../../utils/axios";
import {enqueueSnackbar} from "notistack";
import ScrollX from "../../components/ScrollX";
import ReactTable from "../../components/ReactTable";
import moment from "moment";
import EmojiPicker from "emoji-picker-react";


const validationSchema = yup.object({
    title: yup.string().required('عنوان اعلان را وارد کنید'),
});

const PushNotification = () => {

    const [apps, setApps] = useState([])
    const [checkedItems, setCheckedItems] = useState([]);
    const [history, setHistory] = useState()
    const [lastFieldFocus, setLastFieldFocus] = useState("")
    const titleRef = createRef()
    const messageRef = createRef()
    const formikRef = createRef()

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                accessor: 'id',
                className: 'cell-center',
                disableFilters: true
            },
            {
                Header: 'عنوان',
                accessor: 'title',
                disableFilters: true
            },
            {
                Header: 'پیام',
                accessor: 'message',
                disableFilters: true
            },
            {
                Header: 'لیست گیرنده ها',
                accessor: 'receivers_name',
                disableFilters: true
            },
            {
                Header: 'زمان انتشار',
                className: 'cell-center',
                accessor: 'created_at',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({row}) => {
                    const formattedDate = moment(row.values.created_at).format("YYYY-MM-DD HH:mm");
                    return (
                        <Typography dir={"ltr"}>{formattedDate}</Typography>
                    )
                }
            },
        ])

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setCheckedItems([
                ...checkedItems,
                parseInt(event.target.id)
            ])
        } else {
            setCheckedItems(checkedItems.filter(item => item !== parseInt(event.target.id)))
        }
    };


    useEffect(() => {
        loadAppsData()
        loadHistory()
    }, [])

    const loadAppsData = async () => {
        try {
            let response = await axios.get("/admin/notifications/apps")
            setCheckedItems(response.data.map((e) => e.id))
            setApps(response.data)
        } catch (e) {

        }
    }

    const loadHistory = async () => {
        try {
            let response = await axios.get("/admin/notifications/history")
            setHistory(response.data)
        } catch (e) {

        }
    }

    const add = async (values, helper) => {
        try {
            let response = await axios.post(`/admin/notifications/${values.isTest ? 'sendTest' : 'send'}`, {
                app_ids: checkedItems,
                title: values.title,
                message: values.message
            })
            enqueueSnackbar('نوتیفیکیشن با موفقیت ارسال شد', {variant: 'success'})
            helper.resetForm()
            loadHistory()
        } catch (e) {
            enqueueSnackbar('نوتیفیکیشن ارسال نشد', {variant: 'error'})
        }
    }
    const handleEmojiClick = (emojiObject) => {
        if (lastFieldFocus === "title") {
            if (titleRef.current) {
                const newValue = titleRef.current.value + emojiObject.emoji
                formikRef.current.setFieldValue('title', newValue);
            }
        }
        if (lastFieldFocus === "message") {
            if (messageRef.current) {
                const newValue = messageRef.current.value + emojiObject.emoji
                formikRef.current.setFieldValue('message', newValue);
            }
        }
    };

    return (
        <Stack spacing={4}>
            <MainCard>
                <Formik
                    innerRef={formikRef}
                    enableReinitialize={false}
                    initialValues={{
                        title: '',
                        message: '',
                        isTest: false
                    }}
                    onSubmit={add} validationSchema={validationSchema}>
                    {({
                          handleBlur,
                          errors,
                          handleChange,
                          handleSubmit,
                          values,
                          isValid,
                          setFieldValue,
                          touched
                      }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid container item sm={9}>
                                        <Grid item xs={12} lg={6}>
                                            <Stack spacing={1}>
                                                <InputLabel>عنوان نوتیفیکشن </InputLabel>
                                                <TextField fullWidth placeholder="نام اعلان"
                                                           id={"title"}
                                                           inputRef={titleRef}
                                                           value={values.title}
                                                           onFocus={() => setLastFieldFocus("title")}
                                                           onChange={handleChange}/>
                                            </Stack>
                                            <FormHelperText error={touched.title && errors.title}>سعی کنید عنوانی
                                                مرتبط
                                                بنویسید. اولین چیزی که کاربر در لیست میبیند عنوان اعلان
                                                است</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel>متن نوتیفیکشن را بنویسید</InputLabel>
                                                <TextField fullWidth placeholder="متن اعلام را وارد کنید"
                                                           id={"message"}
                                                           inputRef={messageRef}
                                                           onFocus={() => setLastFieldFocus("message")}
                                                           value={values.message}
                                                           onChange={handleChange}/>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>نوتیفیکشن در این برنامه ها برای تمامی کاربران ارسال
                                                شود (فقط برنامه هایی که دارای شناسه نوتیفیکشن هستند نمایش داده
                                                میشوند):</InputLabel>
                                            <FormControl component="fieldset">
                                                <FormGroup aria-label="position" row
                                                           onChange={(e) => console.log(e)}>
                                                    {apps.map((e) => {
                                                        return (
                                                            <FormControlLabel
                                                                control={<Checkbox id={e.id} defaultChecked
                                                                                   onChange={handleCheckboxChange}/>}
                                                                label={e.name} labelPlacement="end"
                                                                sx={{ml: 1}}/>
                                                        )
                                                    })}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <EmojiPicker
                                            width={"100%"}
                                            onEmojiClick={handleEmojiClick}
                                            searchDisabled
                                        />
                                    </Grid>
                                    <Grid container item xs={12} justifyContent={'end'} textAlign={'end'}
                                          spacing={1}>
                                        <Grid item>
                                            <Button type={'submit'}
                                                    onClick={() => setFieldValue('isTest', true)}
                                                    color="info" variant="outlined"
                                                    startIcon={<Send/>}>
                                                ارسال تست
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button type={'submit'}
                                                    onClick={() => setFieldValue('isTest', false)}
                                                    color="primary" variant="contained"
                                                    startIcon={<Send/>}>
                                                ارسال به کاربران
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </MainCard>


            {
                history &&
                <MainCard title="تاریخچه ارسال ها">
                    <MainCard content={false}>
                        <ScrollX>
                            <ReactTable columns={columns} data={history}/>
                        </ScrollX>
                    </MainCard>
                </MainCard>
            }
        </Stack>

    )
}
export default PushNotification