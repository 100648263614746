import {
    Button, Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import MainCard from "../../components/MainCard";
import axios from "../../utils/axios";
import React, {useEffect, useMemo, useState} from "react";
import {Form, Formik} from "formik";
import {Add, CloseSquare, Eraser} from "iconsax-react";
import {enqueueSnackbar} from "notistack";
import * as yup from "yup";


const validationSchema = yup.object({
    result: yup.string().required('مقدار متغیر را وارد کنید'),
    variable: yup.string().required('متغیر را انتخاب کنید'),
    site_id: yup.number().required('سایت را انتخاب کنید'),
});
const SiteVariables = () => {

    const [sites, setSites] = useState()
    const [sitesVariables, setSitesVariables] = useState([])

    const options = useMemo(() => [
        {
            title: "نسخه اندروید",
            key: "androidAppVersion"
        },
        {
            title: "نسخه ویندوز",
            key: "windowsAppVersion"
        },
        {
            title: "نسخه iOS",
            key: "iosAppVersion"
        },
        {
            title: "لینک نسخه اندروید",
            key: "androidAppLink"
        },
        {
            title: "لینک نسخه ویندوز",
            key: "windowsAppLink"
        },
        {
            title: "لینک نسخه iOS",
            key: "iosAppLink"
        },
        {
            title: "لینک تلگرام",
            key: "telegramLink"
        },
        {
            title: "لینک اینستاگرام",
            key: "instagramLink"
        },
        {
            title: "لینک سایت اصلی",
            key: "mainSiteLink"
        },
    ], [])

    useEffect(() => {
        loadSites()
        loadSitesVariables();
    }, [])

    const findTitleByKey = (key) => {
        return options.filter((e) => e.key === key)[0].title
    }
    const loadSitesVariables = async () => {
        try {
            let servers = await axios.get("/admin/sites/variables")
            setSitesVariables(servers.data)
        } catch (e) {

        }
    }
    const loadSites = async () => {
        try {
            let servers = await axios.get("/admin/sites")
            setSites(servers.data)
        } catch (e) {

        }
    }
    const add = async (values, helper) => {
        try {
            await axios.post("/admin/sites/variables", values)
            loadSitesVariables()
            enqueueSnackbar('متغیر با موفقیت اضافه شد', {variant: 'success'})
            helper.resetForm()
        } catch (e) {
            enqueueSnackbar('افزودن متغیر با خطا مواجه شد', {variant: 'error'})
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MainCard title={"افزودن متغیر یا لینک جدید"}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{result: '', variable: '', site_id: ''}}
                        validationSchema={validationSchema}
                        onSubmit={add}>
                        {({
                              handleBlur,
                              errors,
                              handleChange,
                              handleSubmit,
                              values,
                              isValid,
                              setFieldValue,
                              touched
                          }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} lg={3}>
                                            <Stack spacing={1}>
                                                <InputLabel>سایت</InputLabel>
                                                <Select name="site_id"
                                                        value={values.site_id}
                                                        placeholder="Age" onChange={handleChange}>
                                                    {sites && sites.map((e) => {
                                                        return (
                                                            <MenuItem value={e.id}>{e.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </Stack>
                                            <FormHelperText error={touched.site_id && errors.site_id}>سایت مورد نظر را
                                                انتخاب کنید</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <Stack spacing={1}>
                                                <InputLabel>متغیر</InputLabel>
                                                <Select name="variable"
                                                        value={values.variable} placeholder="Age"
                                                        onChange={handleChange}>
                                                    {options.map((e) => {
                                                        return (
                                                            <MenuItem value={e.key}>{e.title}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </Stack>
                                            <FormHelperText error={touched.variable && errors.variable}>متغیر را انتخاب
                                                کنید</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <Stack spacing={1}>
                                                <InputLabel>مقدار</InputLabel>
                                                <TextField fullWidth placeholder=""
                                                           id={"result"}
                                                           value={values.result}
                                                           onChange={handleChange}/>
                                            </Stack>
                                            <FormHelperText error={touched.result && errors.result}>مقدار متغیر را وارد
                                                کنید</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <Button variant="contained" type={"submit"} startIcon={<Add/>} size="large">
                                                افزودن یا ویرایش متغیر
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>در صورتی که متغیر از قبل تعریف شده باشد صرفا ویرایش
                                                میشود</Typography>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </MainCard>
            </Grid>
            <Grid item xs={12}>
                <MainCard title={"لیست سایت ها"}>
                    <Grid container spacing={2}>

                        {sitesVariables.map((e) => {
                            let undefinedVars = [...options]
                            return (
                                <Grid item xs={12} lg={4}>
                                    <MainCard>
                                        <Stack spacing={2}>
                                            <Typography variant={'h6'} align={'center'}>{e.name}</Typography>
                                            <Divider/>
                                            {e.variables.map((i) => {
                                                undefinedVars = undefinedVars.filter(item => item.key !== i.variable)
                                                return (
                                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                                        <Typography variant={'h6'}
                                                                    align={'center'}>{findTitleByKey(i.variable)}</Typography>
                                                        <Typography variant={'h6'}
                                                                    align={'center'}>{i.result}</Typography>
                                                    </Stack>
                                                )
                                            })}
                                            {undefinedVars.map((e)=>{
                                                return(
                                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                                        <Typography variant={'h6'}
                                                                    align={'center'}>{e.title}</Typography>
                                                        <CloseSquare color={'red'}/>
                                                    </Stack>
                                                )
                                            })}
                                        </Stack>

                                    </MainCard>
                                </Grid>
                            )
                        })}
                    </Grid>
                </MainCard>
            </Grid>
        </Grid>
    )
}
export default SiteVariables