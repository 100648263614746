// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Story, Fatrows, PresentionChart } from 'iconsax-react';

// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'group-sites',
  title: <FormattedMessage id="manageSites" />,
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'statistics',
      title: <FormattedMessage id="sitesList" />,
      type: 'item',
      url: '/sites/list',
      icon: icons.statistics
    },
    {
      id: 'siteBanners',
      title: <FormattedMessage id="siteBanners" />,
      type: 'item',
      url: '/sites/banners',
      icon: icons.statistics
    },
    {
      id: 'siteVars',
      title: <FormattedMessage id="sitesLinkAndVar" />,
      type: 'item',
      url: '/sites/variables',
      icon: icons.statistics
    },
  ]
};

export default widget;
