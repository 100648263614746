import {Form, Formik} from "formik";
import MainCard from "../../components/MainCard";
import {
    Box,
    Button,
    Checkbox, Dialog, DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid, IconButton,
    InputLabel,
    Stack, TableCell,
    TextField, Tooltip, Typography
} from "@mui/material";
import * as yup from "yup";

import {Editor} from 'react-draft-wysiwyg';
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useCallback, useEffect, useMemo, useState} from "react";
import "./test.css"
import {ArrowDown2, ArrowRight2, CloseCircle, DocumentUpload, Edit, Eye, Send, Trash} from "iconsax-react";
import axios from "../../utils/axios";
import {enqueueSnackbar} from "notistack";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ScrollX from "../../components/ScrollX";
import ReactTable, {ReactExpandableTable} from "../../components/ReactTable";
import moment from "moment";
import AlertDelete from "../../components/AlertDelete";

const validationSchema = yup.object({
    title: yup.string().required('عنوان اعلان را وارد کنید'),
});

const InAppMessaging = () => {

    const [apps, setApps] = useState([])
    const [messages, setMessages] = useState([])
    const [deleteMessageId, handleDialogDelete] = useState(undefined)

    const [checkedItems, setCheckedItems] = useState([]);

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setCheckedItems([
                ...checkedItems,
                parseInt(event.target.id)
            ])
        } else {
            setCheckedItems(checkedItems.filter(item => item !== parseInt(event.target.id)))
        }
    };

    useEffect(() => {
        loadAppsData()
        loadInAppMessages()
    }, [])

    const loadAppsData = async () => {
        try {
            let response = await axios.get("/admin/apps")
            setCheckedItems(response.data.map((e) => e.id))
            setApps(response.data)
        } catch (e) {

        }
    }


    const columns = useMemo(
        () => [
            {
                Header: 'شناسه',
                accessor: 'id',
                className: 'cell-center',
                disableFilters: true
            },
            {
                Header: 'عنوان',
                accessor: 'title',
                disableFilters: true
            },
            {
                Header: 'منتشر شده در',
                accessor: 'app_name',
                disableSortBy: true,
                disableFilters: true
            },
            {
                Header: 'شناسه اپلیکیشن',
                accessor: 'app_id',
                disableSortBy: true,
                disableFilters: true
            },
            {
                Header: 'زمان انتشار',
                accessor: 'created_at',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({row}) => {
                    const formattedDate = moment(row.values.created_at).format("YYYY-MM-DD HH:mm");
                    return (
                        <Typography dir={"ltr"}>{formattedDate}</Typography>
                    )
                }
            },
            {
                Header: 'عملیات',
                className: 'cell-center',
                disableSortBy: true,
                Cell: ({row}) => {
                    return (
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                            <Tooltip title={row.isExpanded ? "بستن" : "مشاهده"}>
                                <IconButton
                                    color="primary"
                                    onClick={(e) => {
                                        row.toggleRowExpanded();
                                    }}
                                >
                                    {row.isExpanded ? <CloseCircle/> : <Eye/>}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="حذف">
                                <IconButton
                                    color="error"
                                    onClick={(e) => {
                                        handleDialogDelete(row.values.id)
                                    }}
                                >
                                    <Trash/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    );
                }
            }
        ],
        []
    );

    const add = async (values, helper) => {
        try {
            const contentState = values.content.getCurrentContent();
            const rawContentState = convertToRaw(contentState);
            const html = draftToHtml(rawContentState);

            let response = await axios.post("/admin/messages/multiple", {
                title: values.title,
                message: html,
                app_ids: checkedItems
            })
            enqueueSnackbar('اعلان با موفقیت ثبت شد', {variant: 'success'})
            helper.resetForm()
            loadInAppMessages()
        } catch (e) {
            enqueueSnackbar('ثبت اعلان با خطا مواجه شد', {variant: 'error'})
        }
    }
    const loadInAppMessages = async () => {
        try {
            let response = await axios.get('/admin/messages')
            setMessages(response.data)
        } catch (e) {
            enqueueSnackbar('دریافت لیست اعلان ها با خطا مواجه شد.', {variant: 'error'})
        }
    }

    const deleteMessage = async (id) => {
        try {
            let response = await axios.delete(`/admin/messages/${id}`)
            enqueueSnackbar('اعلان مورد نظر حذف شد', {variant: 'success'})
            loadInAppMessages()
        } catch (e) {
            enqueueSnackbar('حذف اعلان با خطا مواجه شد', {variant: 'error'})
        }
    }

    const renderRowSubComponent = useCallback(({row}) => <TableCell colSpan={8}>
        <div dangerouslySetInnerHTML={{__html: row.original.message}}></div>
    </TableCell>, [messages]);

    return (
        <div>
            <AlertDelete title={"حذف اعلان"} data={deleteMessageId} open={typeof deleteMessageId === 'number'}
                         handleClose={(data, closeDialog) => {
                             deleteMessage(data);
                             handleDialogDelete(closeDialog);
                         }}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MainCard title="افزودن اعلان جدید">
                        <Formik
                            enableReinitialize={false}
                            initialValues={{
                                id: 0,
                                title: '',
                                content: EditorState.createWithContent(ContentState.createFromText("")),
                            }}
                            onSubmit={add} validationSchema={validationSchema}>
                            {({
                                  handleBlur,
                                  errors,
                                  handleChange,
                                  handleSubmit,
                                  values,
                                  isValid,
                                  setFieldValue,
                                  touched
                              }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <Stack spacing={1}>
                                                    <InputLabel>عنوان اعلان (اجباری)</InputLabel>
                                                    <TextField fullWidth placeholder="نام اعلان"
                                                               id={"title"}
                                                               value={values.title}
                                                               onChange={handleChange}/>
                                                </Stack>
                                                <FormHelperText error={touched.title && errors.title}>سعی کنید عنوانی
                                                    مرتبط
                                                    بنویسید. اولین چیزی که کاربر در لیست میبیند عنوان اعلان
                                                    است</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} minHeight={300} width={200}>
                                                <InputLabel>متن اعلان را بنویسید</InputLabel>
                                                <Editor
                                                    editorState={values.content}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(editor) => setFieldValue('content', editor)}
                                                />
                                                <FormHelperText error={touched.content && errors.content}>محتوای اعلان
                                                    باید حداقل دارای 50 حرف باشد</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel>اعلان در این برنامه ها برای تمامی کاربران ارسال
                                                    شود:</InputLabel>
                                                <FormControl component="fieldset">
                                                    <FormGroup aria-label="position" row
                                                               onChange={(e) => console.log(e)}>
                                                        {apps.map((e) => {
                                                            return (
                                                                <FormControlLabel
                                                                    control={<Checkbox id={e.id} defaultChecked
                                                                                       onChange={handleCheckboxChange}/>}
                                                                    label={e.name} labelPlacement="end"
                                                                    sx={{ml: 1}}/>
                                                            )
                                                        })}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} textAlign={'end'}>
                                                <Button type={'submit'} color="primary" variant="contained"
                                                        startIcon={<Send/>}>
                                                    ارسال کن
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </MainCard>
                </Grid>
                <Grid item xs={12}>
                    <MainCard title={"لیست تمامی اعلان ها"}>
                        {messages && <MainCard content={false}>
                            <ScrollX>
                                <ReactExpandableTable columns={columns} data={messages}
                                                      renderRowSubComponent={renderRowSubComponent}/>
                            </ScrollX>
                        </MainCard>}

                        {messages.length === 0 && <Typography textAlign={'center'} variant={'h6'} margin={5}>موردی برای نمایش وجود ندارد</Typography>}
                    </MainCard>
                </Grid>
            </Grid>
        </div>
    )
}
export default InAppMessaging