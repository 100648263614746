// project-imports
import applications from './applications';
import sites from './widget';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [sites,applications]
};

export default menuItems;
