import {lazy} from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AppDetail from "../pages/apps/detail";
import InAppMessaging from "../pages/apps/in_app_messaging";
import SiteBanners from "../pages/sites/site_banners";
import SiteVariables from "../pages/sites/variables";
import PushNotification from "../pages/apps/push_notification";
import AppLinks from "../pages/apps/links";

// render - sites
const SitesList = Loadable(lazy(() => import('pages/sites/list')));
const AppsList = Loadable(lazy(() => import('pages/apps/list')));


// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
// render - sample page
const Landing = Loadable(lazy(() => import('pages/landing')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: 'sites',
                    children: [
                        {
                            path: 'list',
                            element: <SitesList/>
                        },
                        {
                            path: 'banners',
                            element: <SiteBanners/>
                        },
                        {
                            path: 'variables',
                            element: <SiteVariables/>
                        },
                    ]
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'list',
                            children:[
                                {
                                    path: '',
                                    element: <AppsList/>,
                                },
                                {
                                    path: 'details/:appId',
                                    element: <AppDetail/>,
                                },
                                {
                                    path: 'links/:appId',
                                    element: <AppLinks/>,
                                }
                            ]
                        },
                        {
                            path:'messaging',
                            element: <InAppMessaging/>
                        },
                        {
                            path:'pushNotifications',
                            element: <PushNotification/>
                        }
                    ]
                },
            ]
        },
        {
            path: '/',
            element: <CommonLayout layout="landing"/>,
            children: [
                {
                    path: 'landing',
                    element: <Landing/>
                }
            ]
        },
        {
            path: '/maintenance',
            element: <CommonLayout/>,
            children: [
                {
                    path: '404',
                    element: <MaintenanceError/>
                },
                {
                    path: '500',
                    element: <MaintenanceError500/>
                },
            ]
        },
        {
            path: '/auth',
            element: <CommonLayout/>,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin/>
                },
                // {
                //   path: 'register',
                //   element: <AuthRegister />
                // },
                {
                    path: 'forgot-password',
                    element: <AuthForgotPassword/>
                },
                {
                    path: 'reset-password',
                    element: <AuthResetPassword/>
                },
                {
                    path: 'code-verification',
                    element: <AuthCodeVerification/>
                },
            ]
        }
    ]
};

export default MainRoutes;
