// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, Messages2, Calendar1, Kanban, Profile2User, Bill, UserSquare, ShoppingBag } from 'iconsax-react';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="manageApplications" />,
  icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'chat',
      title: <FormattedMessage id="listApps" />,
      type: 'item',
      url: '/apps/list',
      icon: icons.applications,
      breadcrumbs: false,
    },
    {
      id: 'inAppMessaging',
      title: <FormattedMessage id="inappMessaging" />,
      type: 'item',
      url: '/apps/messaging',
      icon: icons.calendar
    },
    {
      id: 'pushNotification',
      title: <FormattedMessage id="pushNotification" />,
      type: 'item',
      icon: icons.kanban,
      url: '/apps/pushNotifications'
    },
  ]
};

export default applications;
