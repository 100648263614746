import {Button, FormHelperText, Grid, InputLabel, Stack, TextField, Typography} from "@mui/material";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import axios from "../../utils/axios";
import MainCard from "../../components/MainCard";
import {Form} from "formik";
import {DocumentUpload} from "iconsax-react";
import Loader from "../../components/Loader";
import {enqueueSnackbar} from "notistack";
import moment from "moment";


const AppLinks = () => {
    const {appId} = useParams();
    const [links, setLinks] = useState()
    const [link, setLink] = useState("")

    useEffect(() => {
        loadLinks()
    }, [])

    const loadLinks = async () => {
        try {
            let response = await axios.get(`/admin/apps/${appId}/urls`)
            setLinks(response.data)
        } catch (e) {

        }
    }

    const handleSubmit = async () => {
        if (link.length === 0) {
            enqueueSnackbar('لینک را وارد کنید', {variant: 'error'})
            return
        }
        let splitedLink = link.split("\n")
        for (const splitedLinkKey in splitedLink) {
            if (!splitedLink[splitedLinkKey].startsWith("https://")) {
                splitedLink[splitedLinkKey] = "https://" + splitedLink[splitedLinkKey]
            }
        }
        try {
            let response = await axios.post(`/admin/apps/${appId}/urls`, {
                urls: splitedLink
            })
            setLink("")
            loadLinks()
            enqueueSnackbar('لینک با موفقیت اضافه شد', {variant: 'success'})
        } catch (e) {
            enqueueSnackbar('افزودن لینک با خطا مواجه شد', {variant: 'error'})
        }
    }

    const deleteLink = async (id) => {
        try {
            let response = await axios.delete(`/admin/apps/urls/${id}`)
            enqueueSnackbar('لینک با موفقیت حذف شد', {variant: 'success'})
            loadLinks()
        } catch (e) {
            enqueueSnackbar('حذف لینک با خطا مواجه شد', {variant: 'error'})
        }
    }

    return (
        <Grid container spacing={3}>
            {typeof links == "undefined" && <Loader/>}
            <Grid item xs={12}>
                <MainCard title={"افزودن لینک بصورت گروهی"}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={1}>
                                <InputLabel>لینک سایت</InputLabel>
                                <TextField fullWidth placeholder="google.com"
                                           id={"name"}
                                           multiline
                                           rows={8}
                                           dir={'ltr'}
                                           value={link}
                                           onChange={(e) => setLink(e.target.value)}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} textAlign={'end'}>
                            <Button onClick={() => handleSubmit()} color="primary" variant="contained"
                                    endIcon={<DocumentUpload/>}>
                                افزودن لینک ها
                            </Button>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                {Array.isArray(links) && links.map((e) => {
                    return (
                        <Grid item xs={12} lg={3}>
                            <MainCard>
                                <Stack spacing={2}>
                                    <Stack justifyContent={'space-between'} direction={'row'}>
                                        <Typography>لینک</Typography>
                                        <Typography fontWeight={"bold"}>{e.url}</Typography>
                                    </Stack>
                                    <Stack justifyContent={'space-between'} direction={'row'}>
                                        <Typography>افزوده شده در</Typography>
                                        <Typography
                                            dir={'ltr'}>{moment(e.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
                                    </Stack>

                                    <Button onClick={() => deleteLink(e.id)} color="error" variant="contained">
                                        حذف لینک
                                    </Button>
                                </Stack>
                            </MainCard>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
export default AppLinks